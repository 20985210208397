<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>  
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/base-curricular'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/base-curricular')"
              >Base curricular</a>
            </li>             
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Componentes</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Componentes da base 
                  <span>curricular</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-4">
                  <h1>{{ fastBaseCurricular.nome_base_curricular }}</h1>
                  <h6 v-if="fastBaseCurricular.carga_horaria">
                    Carga horária total: {{ fastBaseCurricular.carga_horaria }}h
                  </h6>
                  <h6>Ano: {{ fastBaseCurricular.ano_faixa }}º</h6>
                  <button
                    class="btn btn-sm btn-primary mt-4 d-none"
                    @click="gerarPdfBaseCurricular()"
                  >
                    <b-icon-file-earmark /> Gerar PDF
                  </button>
                </div>
                <div class="col-12 mt-4">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de componentes
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="showModal('modalCriarComponentes')"
                          >
                            <small>+ Criar componente</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th><small class="font-weight-bold">Sequência</small></th>
                                <th><small class="font-weight-bold">Nome do componente</small></th>
                                <th><small class="font-weight-bold">Habilidades</small></th>
                                <th><small class="font-weight-bold" /></th>
                                <th><small class="font-weight-bold" /></th>
                                <th><small class="font-weight-bold" /></th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastComponentesLoading">
                              <tr>
                                <td
                                  colspan="6"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastComponentes.length">
                              <tr
                                v-for="(e, index) in fastComponentes"
                                :key="index"
                              >
                                <td class="align-middle text-center">
                                  <small>{{ e.sequencia }}</small>                                  
                                </td>
                                <td class="align-middle">
                                  <small>{{ e.unidade_curricular_tematica }}</small>                                  
                                </td>
                                <td class="align-middle">
                                  <small>{{ e.habilidade }}</small>                                  
                                </td>
                                <td class="text-center align-middle"> 
                                  <button
                                    class="btn btn-sm btn-success pt-0 pb-0 d-block mx-auto mb-1"
                                    @click="exibeModalBaseBibliograficaAdicionada(e)"
                                  >
                                    <small>Bibliografia <span class="badge badge-light">{{ e.total_bibliografia }}</span></small>
                                  </button>   
                                </td>
                                <td class="text-center align-middle"> 
                                  <button
                                    class="btn btn-sm btn-success pt-0 pb-0 d-block mx-auto mb-1"
                                    @click="exibeModalBaseCompetenciaAdicionada(e)"
                                  >
                                    <small>Competências <span class="badge badge-light">{{ e.total_competencia }}</span></small>
                                  </button>   
                                </td>
                                <td class="text-center align-middle"> 
                                  <button
                                    class="btn btn-sm btn-success pt-0 pb-0 d-block mx-auto mb-1"
                                    @click="exibeModalBaseIndicadorAdicionada(e)"
                                  >
                                    <small>Indicadores <span class="badge badge-light">{{ e.total_indicador }}</span></small>
                                  </button>    
                                </td>
                                <td class="text-center align-middle">                                                
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1"
                                    @click="exibeModalEditarComponentes(e)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-danger pt-0 pb-0 ml-1 mr-1"
                                    @click="exibeModalExcluirComponentes(e)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="6"
                                  class="text-center"
                                >
                                  Nenhum componentes cadastrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/base-curricular'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/base-curricular')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarComponentes"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo componente</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarComponentes')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row"> 
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Nome do componente <span class="text-danger">*</span></label>
              <input
                v-model="fastComponentesNovo.unidade_curricular_tematica"
                type="text"
                class="form-control"
                maxlength="255"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Objeto de conhecimento</label>
              <input
                v-model="fastComponentesNovo.objeto_de_conhecimento"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Habilidades</label>
              <textarea
                v-model="fastComponentesNovo.habilidade"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Observações</label>
              <textarea
                v-model="fastComponentesNovo.comentario"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Possibilidade para currículo</label>
              <textarea
                v-model="fastComponentesNovo.possibilidade_para_curriculo"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Disciplina</label>
              <select
                v-model="fastComponentesNovo.id_area_disciplina"
                class="form-control"
              >
                <option value="0">
                  Nenhuma
                </option>
                <option
                  v-for="(d, index) in fastDisciplinas"
                  :key="index"
                  :value="d.id_plataforma_area_disciplina"
                >
                  {{ d.descricao }}
                </option>
              </select>
              <small
                v-if="!fastDisciplinas.length"
                class="text-danger"
              >Nenhuma disciplina cadastrada na plataforma</small>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4 text-center">
              <label>Crédito matrícula</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastComponentesNovo.credito_matricula"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4 text-center">
              <label>Crédito cobrança</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastComponentesNovo.credito_cobranca"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4 text-center">
              <label>Extra curricular</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastComponentesNovo.extra_curricular"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Estágio</label>
              <select
                v-model="fastComponentesNovo.usa_estagio"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Prova de proficiência</label>
              <select
                v-model="fastComponentesNovo.exige_prova_proficiencia"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Carga horária semanal</label>
              <input
                v-model="fastComponentesNovo.carga_horaria_semanal"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Carga horária semestral</label>
              <input
                v-model="fastComponentesNovo.carga_horaria_semestral"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Carga horária total</label>
              <input
                v-model="fastComponentesNovo.carga_horaria_total"
                type="number"
                class="form-control"
              >
            </div>
              
            
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarComponentes()"
                >
                  Criar componentes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarComponentes"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar componente</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarComponentes')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row"> 
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Nome do componente</label>
              <input
                v-model="fastComponentesEditar.unidade_curricular_tematica"
                type="text"
                class="form-control"
                maxlength="255"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Objeto de conhecimento</label>
              <input
                v-model="fastComponentesEditar.objeto_de_conhecimento"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Habilidades</label>
              <textarea
                v-model="fastComponentesEditar.habilidade"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Observações</label>
              <textarea
                v-model="fastComponentesEditar.comentario"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Possibilidade para currículo</label>
              <textarea
                v-model="fastComponentesEditar.possibilidade_para_curriculo"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Disciplina</label>
              <select
                v-model="fastComponentesEditar.id_area_disciplina"
                class="form-control"
              >
                <option value="0">
                  Nenhuma
                </option>
                <option
                  v-for="(d, index) in fastDisciplinas"
                  :key="index"
                  :value="d.id_plataforma_area_disciplina"
                >
                  {{ d.descricao }}
                </option>
              </select>
              <small
                v-if="!fastDisciplinas.length"
                class="text-danger"
              >Nenhuma disciplina cadastrada na plataforma</small>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4 text-center">
              <label>Crédito matrícula</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastComponentesEditar.credito_matricula"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4 text-center">
              <label>Crédito cobrança</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastComponentesEditar.credito_cobranca"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4 text-center">
              <label>Extra curricular</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastComponentesEditar.extra_curricular"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Estágio</label>
              <select
                v-model="fastComponentesEditar.usa_estagio"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Prova de proficiência</label>
              <select
                v-model="fastComponentesEditar.exige_prova_proficiencia"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Carga horária semanal</label>
              <input
                v-model="fastComponentesEditar.carga_horaria_semanal"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Carga horária semestral</label>
              <input
                v-model="fastComponentesEditar.carga_horaria_semestral"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Carga horária total</label>
              <input
                v-model="fastComponentesEditar.carga_horaria_total"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Sequência</label>
              <input
                v-model="fastComponentesEditar.sequencia"
                type="number"
                class="form-control"
              >
            </div>
              
            
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarComponentes()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirComponentes"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir componentes?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirComponentes')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirComponentes()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalBaseBibliografica"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-12 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalBaseBibliograficaAdicionada', 'modalBaseBibliografica')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista bibliográfica da plataforma
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Filtro por nome ou autor do livro"
                        @keyup="filtroNomeLivro"
                      >
                    </div>                          
                  </div>
                  <div class="row">
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm">
                        <thead class="thead-dark">
                          <tr class="text-center">
                            <th class="text-center">
                              <small class="font-weight-bold">ISBN</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Nome do livro</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Autor(a)</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">ano</small>
                            </th><th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastBibliograficaLoading">
                          <tr>
                            <td
                              colspan="5"
                              class="text-center"
                            >
                              Carregando...
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else-if="fastBibliograficaPaginacao.length">
                          <tr
                            v-for="(e, index) in fastBibliograficaPaginacao"
                            :key="index"
                          >
                            <td class="align-middle text-center">
                              <small v-if="e.isbn">
                                <span v-if="e.isbn.trim()">{{ e.isbn }}</span>
                                <span v-else>Não registrado</span>
                              </small>
                              <small v-else>Não registrado</small>
                            </td>
                            <td class="align-middle text-center">
                              <small>{{ e.nome_livro }}</small>
                            </td>
                            <td class="align-middle text-center">
                              <small>{{ e.autora }}</small>
                            </td>
                            <td class="align-middle text-center">
                              <small>{{ e.ano }}</small>
                            </td>
                            <td class="text-center align-middle">                                  
                              <button
                                v-if="!verificaBibliografiaAdicionada(e.id_bibliografia)"
                                class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                @click="adicionarBibliografia(e)"
                              >
                                <small>+ Adicionar</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="5"
                              class="text-center"
                            >
                              Nenhum dado bibliográfico cadastrado
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="10"
                        :items="fastBibliograficaFiltro"
                        @changePage="
                          fastBibliograficaPaginacao = $event
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                class="btn btn-secondary"
                @click.prevent="showModal('modalBaseBibliograficaAdicionada', 'modalBaseBibliografica')"
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalBaseBibliograficaAdicionada"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-12 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalBaseBibliograficaAdicionada')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista bibliográfica do componente
                      </h6>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                      <button
                        class="btn btn-sm btn-primary"
                        @click.prevent="showModal('modalBaseBibliografica', 'modalBaseBibliograficaAdicionada')"
                      >
                        <small>+ Adicionar bibliografia</small>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Filtro por nome ou autor do livro"
                        @keyup="filtroNomeLivro"
                      >
                    </div>                          
                  </div>
                  <div class="row">
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm">
                        <thead class="thead-dark">
                          <tr class="text-center">
                            <th class="text-center">
                              <small class="font-weight-bold">ISBN</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Nome do livro</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Autor(a)</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">ano</small>
                            </th><th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastBibliograficaAdicionadaLoading">
                          <tr>
                            <td
                              colspan="5"
                              class="text-center"
                            >
                              Carregando...
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else-if="fastBibliograficaAdicionadaPaginacao.length">
                          <tr
                            v-for="(e, index) in fastBibliograficaAdicionadaPaginacao"
                            :key="index"
                          >
                            <td class="align-middle text-center">
                              <small v-if="e.isbn">
                                <span v-if="e.isbn.trim()">{{ e.isbn }}</span>
                                <span v-else>Não registrado</span>
                              </small>
                              <small v-else>Não registrado</small>
                            </td>
                            <td class="align-middle text-center">
                              <small>{{ e.nome_livro }}</small>
                            </td>
                            <td class="align-middle text-center">
                              <small>{{ e.autora }}</small>
                            </td>
                            <td class="align-middle text-center">
                              <small>{{ e.ano }}</small>
                            </td>
                            <td class="text-center align-middle">                                  
                              <button
                                class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                @click="removerBibliografia(e)"
                              >
                                <small>- Remover</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="5"
                              class="text-center"
                            >
                              Nenhum dado bibliográfico cadastrado
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="10"
                        :items="fastBibliograficaAdicionadaFiltro"
                        @changePage="
                          fastBibliograficaAdicionadaPaginacao = $event
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalBaseCompetenciaAdicionada"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-12 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalBaseCompetenciaAdicionada')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista de competências do componente
                      </h6>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                      <button
                        class="btn btn-sm btn-primary"
                        @click.prevent="showModal('modalBaseCompetenciaNovo', 'modalBaseCompetenciaAdicionada')"
                      >
                        <small>+ Adicionar competência</small>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm">
                        <thead class="thead-dark">
                          <tr class="text-center">
                            <th><small class="font-weight-bold">Competência</small></th>
                            <th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastCompetenciaAdicionadaLoading">
                          <tr>
                            <td
                              colspan="5"
                              class="text-center"
                            >
                              Carregando...
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else-if="fastCompetenciaAdicionadaPaginacao.length">
                          <tr
                            v-for="(e, index) in fastCompetenciaAdicionadaPaginacao"
                            :key="index"
                          >
                            <td class="align-middle">
                              <small>{{ e.nome_competencia }}</small>
                            </td>
                            <td class="text-center align-middle">     
                              <button
                                class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                @click="exibeModalBaseCompetenciaEditar(e)"
                              >
                                <small>Editar</small>
                              </button>                              
                              <button
                                class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                @click="removerCompetencia(e)"
                              >
                                <small>- Remover</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="5"
                              class="text-center"
                            >
                              Nenhuma competência cadastrada
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="10"
                        :items="fastCompetenciaAdicionadaFiltro"
                        @changePage="
                          fastCompetenciaAdicionadaPaginacao = $event
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalBaseCompetenciaNovo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Nova competência</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalBaseCompetenciaAdicionada', 'modalBaseCompetenciaNovo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label>Nome da competência</label>
              <textarea
                v-model="fastCompetenciaNovo.nome_competencia"
                cols="40"
                class="form-control"
              />
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-primary mr-1"
                  @click.prevent="criarCompetencia()"
                >
                  Cadastrar competência
                </button>
                <button
                  class="btn btn-sm btn-secondary mr-1"
                  @click.prevent="showModal('modalBaseCompetenciaAdicionada', 'modalBaseCompetenciaNovo')"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalBaseCompetenciaEditar"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Editar competência</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalBaseCompetenciaAdicionada', 'modalBaseCompetenciaEditar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label>Nome da competência</label>
              <textarea
                v-model="fastCompetenciaEditar.nome_competencia"
                cols="40"
                class="form-control"
              />
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-primary mr-1"
                  @click.prevent="editarCompetencia()"
                >
                  Confirmar alterações
                </button>
                <button
                  class="btn btn-sm btn-secondary mr-1"
                  @click.prevent="showModal('modalBaseCompetenciaAdicionada', 'modalBaseCompetenciaEditar')"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalBaseIndicadorAdicionada"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-12 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalBaseIndicadorAdicionada')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista de indicadores do componente
                      </h6>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                      <button
                        class="btn btn-sm btn-primary"
                        @click.prevent="showModal('modalBaseIndicadorNovo', 'modalBaseIndicadorAdicionada')"
                      >
                        <small>+ Adicionar indicador</small>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm">
                        <thead class="thead-dark">
                          <tr class="text-center">
                            <th><small class="font-weight-bold">Indicador</small></th>
                            <th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastIndicadorAdicionadaLoading">
                          <tr>
                            <td
                              colspan="5"
                              class="text-center"
                            >
                              Carregando...
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else-if="fastIndicadorAdicionadaPaginacao.length">
                          <tr
                            v-for="(e, index) in fastIndicadorAdicionadaPaginacao"
                            :key="index"
                          >
                            <td class="align-middle">
                              <small>{{ e.nome_indicador }}</small>
                            </td>
                            <td class="text-center align-middle">    
                              <button
                                class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                @click="exibeModalBaseIndicadorEditar(e)"
                              >
                                <small>Editar</small>
                              </button>                              
                              <button
                                class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                @click="removerIndicador(e)"
                              >
                                <small>- Remover</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="5"
                              class="text-center"
                            >
                              Nenhum indicador cadastrado
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="10"
                        :items="fastIndicadorAdicionadaFiltro"
                        @changePage="
                          fastIndicadorAdicionadaPaginacao = $event
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalBaseIndicadorNovo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Novo indicador</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalBaseIndicadorAdicionada', 'modalBaseIndicadorNovo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label>Nome do indicador</label>
              <textarea
                v-model="fastIndicadorNovo.nome_indicador"
                cols="40"
                class="form-control"
              />
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-primary mr-1"
                  @click.prevent="criarIndicador()"
                >
                  Cadastrar indicador
                </button>
                <button
                  class="btn btn-sm btn-secondary mr-1"
                  @click.prevent="showModal('modalBaseIndicadorAdicionada', 'modalBaseIndicadorNovo')"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalBaseIndicadorEditar"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Editar indicador</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalBaseIndicadorAdicionada', 'modalBaseIndicadorEditar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label>Nome do indicador</label>
              <textarea
                v-model="fastIndicadorEditar.nome_indicador"
                cols="40"
                class="form-control"
              />
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-primary mr-1"
                  @click.prevent="editarIndicador()"
                >
                  Confirmar alterações
                </button>
                <button
                  class="btn btn-sm btn-secondary mr-1"
                  @click.prevent="showModal('modalBaseIndicadorAdicionada', 'modalBaseIndicadorEditar')"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <VueHtml2pdf
      ref="html2Pdf"
      class="mx-auto"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="960px"
      :manual-pagination="false"
      :paginate-elements-by-height="10000"
    >
      <section slot="pdf-content">
        <div v-html="htmlBaseCurricular" />
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import VueHtml2pdf from "vue-html2pdf"; //https://www.npmjs.com/package/vue-html2pdf

export default {
  name: "HomeInternoSecretariaBaseCurricularComponentes",
  components: {Pagination, VueHtml2pdf},
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastBaseCurricular: {
        id_base_curricular: "",
        nome_base_curricular: "",
        data_cadastro: "",
        data_inicio_utilizacao: "",
        data_fim_conteudo: "",
        id_usuario: "",
        id_professor_tutor: "",
        id_usuario_revisor: "",
        status_projeto: "",
        modalidade_ead: "",
        modalidade_presencial: "",
        modalidade_hibrido: "",
        nivel: "",
        ano_faixa: "",
        nr_sugerido_aluno: "",
        carga_horaria: "",
        eixo_tecnologico: "",
        segmento: "",
        objetivos: "",
        requisitos_para_acesso: "",
        competencia_principal: "",
        material_didatico_sugerido: "",
        conhecimento_necessario: "",
        habilidade: "",
        atitude_valores: "",
        fonte: "",
        id_plataforma: 0
      },
      // Componentes
      fastComponentes: [],
      fastComponentesLoading: true,
      fastComponentesNovo: {
        id_base_curricular_componente: 0,
        id_base_curricular: 0,
        id_area_disciplina: 0,
        unidade_curricular_tematica: "",
        objeto_de_conhecimento: "",
        habilidade: "",
        comentario: "",
        possibilidade_para_curriculo: "",
        credito_matricula: false,
        credito_cobranca: false,
        extra_curricular: false,
        usa_estagio: "S",
        exige_prova_proficiencia: "S",
        carga_horaria_semanal: 0,
        carga_horaria_semestral: 0,
        carga_horaria_total: 0,
        id_plataforma: this.$route.params.id_plataforma,
        sequencia: 1
      },
      fastComponentesEditar: {
        id_base_curricular_componente: "",
        id_base_curricular: "",
        id_area_disciplina: 0,
        unidade_curricular_tematica: "",
        objeto_de_conhecimento: "",
        habilidade: "",
        comentario: "",
        possibilidade_para_curriculo: "",
        credito_matricula: false,
        credito_cobranca: false,
        extra_curricular: false,
        usa_estagio: "",
        exige_prova_proficiencia: "",
        carga_horaria_semanal: 0,
        carga_horaria_semestral: 0,
        carga_horaria_total: 0,
        id_plataforma: 0,
        sequencia: ""
      },
      // Bibliografia
      fastBibliograficaTotal: [],
      fastBibliograficaFiltro: [],
      fastBibliograficaPaginacao: [],
      fastBibliograficaLoading: true,
      fastBibliograficaAdicionadaTotal: [],
      fastBibliograficaAdicionadaFiltro: [],
      fastBibliograficaAdicionadaPaginacao: [],
      fastBibliograficaAdicionadaLoading: true,
      fastBibliograficaNovo: {
        id_bibliografia: "",
        id_base_curricular_componente: ""
      },
      // Competencias
      fastCompetenciaAdicionadaTotal: [],
      fastCompetenciaAdicionadaFiltro: [],
      fastCompetenciaAdicionadaPaginacao: [],
      fastCompetenciaAdicionadaLoading: true,
      fastCompetenciaNovo: {
        id_competencia: 0,
        id_base_curricular_componente: 0,
        id_plataforma: this.$route.params.id_plataforma,
        nome_competencia: ""
      },
      fastCompetenciaEditar: {
        id_competencia: "",
        id_base_curricular_componente: "",
        id_plataforma: 0,
        nome_competencia: ""
      },
      // Indicadores
      fastIndicadorAdicionadaTotal: [],
      fastIndicadorAdicionadaFiltro: [],
      fastIndicadorAdicionadaPaginacao: [],
      fastIndicadorAdicionadaLoading: true,
      fastIndicadorNovo: {
        id_indicador: 0,
        id_base_curricular_componente: 0,
        id_plataforma: this.$route.params.id_plataforma,
        nome_indicador: ""
      },
      fastIndicadorEditar: {
        id_indicador: "",
        id_base_curricular_componente: "",
        id_plataforma: 0,
        nome_indicador: ""
      },
      htmlToPdfOptions: {
        margin: 0.5,
        filename: 'Base_curricular.pdf',
        image: {
            type: 'jpeg',
            quality: 1
        },
        enableLinks: false,
        html2canvas: {
            scale: 1,
            scrollX: 0,
            scrollY: 0,
            useCORS: true,
            width: 960,
        },
        jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait'
        }        
      },
      htmlBaseCurricular: '',
      // Disciplinas
      fastDisciplinas: []
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Bibliografia
            this.getBibliografica(this.$route.params.id_plataforma)
            // Recupera base curricular
            this.getBaseCurricular(this.$route.params.id_plataforma, this.$route.params.id_base_curricular);
            // Recupera componentes
            this.getComponentes(this.$route.params.id_base_curricular);
            // Disciplinas
            this.getFastDisciplinas();
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {    
    async getBaseCurricular(id_plataforma, id_base_curricular){
      this.promiseGetFastApi("api/projeto_base_curricular/lista", "id_plataforma=" + id_plataforma + "&id_base_curricular=" + id_base_curricular).then(obj => {
        if (obj.length) this.fastBaseCurricular = obj[0]
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar base curricular", "error");
        this.$store.state.fastCarregando = false;
      })
    },
    async getComponentes(id_base_curricular){
      this.fastComponentesLoading = true
      this.promiseGetFastApi("api/projeto_base_curricular_componente/lista", "id_base_curricular=" + id_base_curricular).then(obj => {
        console.log("getComponentes", obj)
        if (obj.length) this.fastComponentes = obj
        else    
            this.fastComponentes = [];

        this.fastComponentesNovo.sequencia = obj.length + 1
        this.$store.state.fastCarregando = false;
        this.fastComponentesLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar componentes da curricular", "error");
        this.$store.state.fastCarregando = false;
        this.fastComponentesLoading = false
      })
    },
    retornaBaseNivel(nivel) {
      switch (nivel) {
        case 1:
          return 'Ensino infantil'
          break;
        case 2:
          return 'Ensino fundamental'
          break;
        case 3:
          return 'Ensino médio'
          break;
        case 4:
          return 'EJA'
          break;
        case 5:
          return 'Técnico'
          break;        
        case 6:
          return 'Ensino superior'
          break;
        case 7:
          return 'Pós-graduação'
          break;
        case 8:
          return 'Mestrado'
          break;
        case 9:
          return 'Doutorado'
          break;        
        default:
          break;
      }
    },
    async criarComponentes() {
      
      let erros = []

      if (!this.fastComponentesNovo.unidade_curricular_tematica) erros.push("O nome do componente é obrigatório")
      
      this.fastComponentesNovo.id_plataforma = this.$route.params.id_plataforma
      this.fastComponentesNovo.id_base_curricular = this.$route.params.id_base_curricular

      console.log("this.fastComponentesNovo", this.fastComponentesNovo)

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.$store.state.fastCarregando = true;
          this.promiseInserirFastApi(this.fastComponentesNovo, "projeto_base_curricular_componente").then((res) => {            
              this.exibeToasty("Componentes criado com sucesso", "success");
              this.hideModal("modalCriarComponentes");
              this.fastComponentes = res;
              this.$store.state.fastCarregando = false;
          }).catch((e) => {
              this.exibeToasty("Erro ao criar componentes", "error");
              this.$store.state.fastCarregando = false;
          });
      }      
    },
    exibeModalEditarComponentes(componentes) {
      if (!componentes.id_area_disciplina) componentes.id_area_disciplina = 0
      this.fastComponentesEditar = componentes;      
      this.showModal("modalEditarComponentes");
    },
    async editarComponentes() {
      
      let erros = []
        if (!this.fastComponentesEditar.unidade_curricular_tematica) erros.push("O nome do componente é obrigatório")

        this.fastComponentesEditar.id_plataforma = this.$route.params.id_plataforma
        this.fastComponentesEditar.id_base_curricular = this.$route.params.id_base_curricular
        if (this.fastComponentesEditar.exige_prova_proficiencia) this.fastComponentesEditar.exige_prova_proficiencia.trim() 
        if (this.fastComponentesEditar.usa_estagio) this.fastComponentesEditar.usa_estagio.trim() 

        if (!this.fastComponentesEditar.carga_horaria_semanal) this.fastComponentesEditar.carga_horaria_semanal = 0
        if (!this.fastComponentesEditar.carga_horaria_semestral) this.fastComponentesEditar.carga_horaria_semestral = 0
        if (!this.fastComponentesEditar.carga_horaria_total) this.fastComponentesEditar.carga_horaria_total = 0
        if (!this.fastComponentesEditar.id_area_disciplina) this.fastComponentesEditar.id_area_disciplina = 0

        console.log("this.fastComponentesEditar", this.fastComponentesEditar)

        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
          this.$store.state.fastCarregando = true;
            this.promiseAtualizarFastApi(this.fastComponentesEditar, "projeto_base_curricular_componente").then(res => {
              console.log("editarComponentes", res)
              this.fastComponentes = res;
                this.exibeToasty("Salvo com sucesso", "success");                
                this.hideModal("modalEditarComponentes");
                this.$store.state.fastCarregando = false;
            }).catch(e => {
                this.exibeToasty("Erro ao salvar alterações", "error");
                this.$store.state.fastCarregando = false;
            });
        }        
    },
    exibeModalExcluirComponentes(componentes) {
      this.fastComponentesEditar = componentes;
      this.showModal("modalExcluirComponentes");
    },
    async excluirComponentes(){
        this.$store.state.fastCarregando = true;
        this.promiseExcluirFastApi(this.fastComponentesEditar, "projeto_base_curricular_componente").then(res => {
          this.fastComponentes = res;
            this.exibeToasty("Componentes excluído com sucesso", "success");   
            this.hideModal("modalExcluirComponentes");
            this.$store.state.fastCarregando = false;
        }).catch(e => {
            this.exibeToasty("Erro ao excluir", "error");
            this.$store.state.fastCarregando = false;
        });
    },
    // Bibliografia
    async getBibliografica(id_plataforma){
      this.promiseGetFastApi("api/projeto_base_bibliografia/lista", "id_plataforma=" + id_plataforma + "&id_bibliografia=0").then(obj => {
        if (obj.length) {
          this.fastBibliograficaTotal = obj
          this.fastBibliograficaFiltro = obj
        }
        else {
          this.fastBibliograficaTotal = []
          this.fastBibliograficaFiltro = []
        }            
        this.$store.state.fastCarregando = false;
        this.fastBibliograficaLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar bibliografica", "error");
        this.$store.state.fastCarregando = false;
        this.fastBibliograficaLoading = false
      })
    },
    filtroNomeLivro(e) {
      let text = e.target.value;
      this.fastBibliograficaFiltro = this.fastBibliograficaTotal.filter(
        (e) => {
          return (
            e.nome_livro.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.autora.toLowerCase().indexOf(text.toLowerCase()) != "-1"
          );
        }
      );
    },
    exibeModalBaseBibliograficaAdicionada(componente) {
      this.fastBibliograficaAdicionadaLoading = true
      this.fastBibliograficaNovo.id_base_curricular_componente = componente.id_base_curricular_componente
      this.showModal('modalBaseBibliograficaAdicionada')
      this.promiseGetFastApi("api/projeto_base_curricular_bibliografia/lista", "id_base_curricular_componente=" + this.fastBibliograficaNovo.id_base_curricular_componente).then(obj => {
        if (obj.length) {
          this.fastBibliograficaAdicionadaTotal = obj
          this.fastBibliograficaAdicionadaFiltro = obj
        }
        else {
          this.fastBibliograficaAdicionadaTotal = []
          this.fastBibliograficaAdicionadaFiltro = []
        }            
        this.$store.state.fastCarregando = false;
        this.fastBibliograficaAdicionadaLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar bibliografica", "error");
        this.$store.state.fastCarregando = false;
        this.fastBibliograficaAdicionadaLoading = false
      })
    },
    adicionarBibliografia(bibliografia){
      this.fastBibliograficaNovo.id_bibliografia = bibliografia.id_bibliografia
      
      this.promiseInserirFastApi(this.fastBibliograficaNovo, "projeto_base_curricular_bibliografia").then((res) => {      
        console.log("this.fastBibliograficaNovo", res)      
          this.exibeToasty("Bibliografia adicionada com sucesso", "success");
          this.fastBibliograficaAdicionadaTotal = res
          this.fastBibliograficaAdicionadaFiltro = res
          this.$store.state.fastCarregando = false;

          this.fastComponentes.forEach(c => { if (c.id_base_curricular_componente == this.fastBibliograficaNovo.id_base_curricular_componente) c.total_bibliografia++ })
      }).catch((e) => {
          this.exibeToasty("Erro ao inserir bibliografia", "error");
          this.$store.state.fastCarregando = false;
      });
    },
    verificaBibliografiaAdicionada(id_bibliografia){
      const count = this.fastBibliograficaAdicionadaTotal.filter(b => id_bibliografia == b.id_bibliografia)
      return count.length
    },
    removerBibliografia(bibliografia){
      this.fastBibliograficaNovo.id_bibliografia = bibliografia.id_bibliografia
      this.promisePostFastApi(this.fastBibliograficaNovo, "api/projeto_base_curricular_bibliografia/exclui").then((res) => {            
          this.exibeToasty("Bibliografia excluída com sucesso", "success");
          this.fastBibliograficaAdicionadaTotal = res
          this.fastBibliograficaAdicionadaFiltro = res
          this.$store.state.fastCarregando = false;
          this.fastComponentes.forEach(c => { if (c.id_base_curricular_componente == this.fastBibliograficaNovo.id_base_curricular_componente) c.total_bibliografia-- })
      }).catch((e) => {
          this.exibeToasty("Erro ao excluir bibliografia", "error");
          this.$store.state.fastCarregando = false;
      });
    },
    // Competencias
    async exibeModalBaseCompetenciaAdicionada(componente){
      this.fastCompetenciaAdicionadaLoading = true
      this.fastCompetenciaEditar.id_base_curricular_componente = componente.id_base_curricular_componente
      this.fastCompetenciaNovo.id_base_curricular_componente = componente.id_base_curricular_componente
      this.showModal('modalBaseCompetenciaAdicionada')
      this.promiseGetFastApi("api/projeto_base_curricular_componente_competencia/lista", "id_base_curricular_componente=" + componente.id_base_curricular_componente).then(obj => {
        if (obj.length) {
          this.fastCompetenciaAdicionadaTotal = obj
          this.fastCompetenciaAdicionadaFiltro = obj
        }
        else {
          this.fastCompetenciaAdicionadaTotal = []
          this.fastCompetenciaAdicionadaFiltro = []
        }            
        this.$store.state.fastCarregando = false;
        this.fastCompetenciaAdicionadaLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar competências", "error");
        this.$store.state.fastCarregando = false;
        this.fastCompetenciaAdicionadaLoading = false
    })
    },
    async criarCompetencia() {      
      if (!this.fastCompetenciaNovo.nome_competencia) {
        this.exibeToasty("O nome é obrigatório", "error");
      } else {
        this.promisePostFastApi(this.fastCompetenciaNovo, "api/projeto_base_curricular_componente_competencia/insere").then((res) => {          
            this.exibeToasty("Competência adicionada com sucesso", "success");
            this.fastCompetenciaAdicionadaTotal = res
            this.fastCompetenciaAdicionadaFiltro = res
            this.showModal('modalBaseCompetenciaAdicionada', 'modalBaseCompetenciaNovo')
            this.fastComponentes.forEach(c => { if (c.id_base_curricular_componente == this.fastCompetenciaNovo.id_base_curricular_componente) c.total_competencia++ })
            this.fastCompetenciaNovo.nome_competencia = ""
        }).catch((e) => {
            this.exibeToasty("Erro ao inserir competência", "error");
        });
      }
    },
    exibeModalBaseCompetenciaEditar(competencia){
      this.fastCompetenciaEditar = competencia
      this.showModal('modalBaseCompetenciaEditar', 'modalBaseCompetenciaAdicionada')
    },
    async editarCompetencia(){
      console.log("this.fastCompetenciaEditar", this.fastCompetenciaEditar)
      if (!this.fastCompetenciaEditar.nome_competencia) {
        this.exibeToasty("O nome é obrigatório", "error");
      } else {
      this.promisePostFastApi(this.fastCompetenciaEditar, "api/projeto_base_curricular_componente_competencia/atualiza").then((res) => {  
            this.fastCompetenciaAdicionadaTotal = res
            this.fastCompetenciaAdicionadaFiltro = res          
            this.exibeToasty("Competência atualizada com sucesso", "success");
            this.showModal('modalBaseCompetenciaAdicionada', 'modalBaseCompetenciaEditar')
        }).catch((e) => {
            this.exibeToasty("Erro ao atualizar competência", "error");
        });
      }
    },
    async removerCompetencia(competencia){
      this.fastCompetenciaEditar.id_competencia = competencia.id_competencia
      this.promisePostFastApi(this.fastCompetenciaEditar, "api/projeto_base_curricular_componente_competencia/exclui").then((res) => {            
          this.exibeToasty("Competência excluída com sucesso", "success");
          this.fastCompetenciaAdicionadaTotal = res
          this.fastCompetenciaAdicionadaFiltro = res
          this.fastComponentes.forEach(c => { if (c.id_base_curricular_componente == this.fastCompetenciaEditar.id_base_curricular_componente) c.total_competencia-- })
      }).catch((e) => {
          this.exibeToasty("Erro ao excluir competência", "error");
      });
    },
    // Indicadores
    async exibeModalBaseIndicadorAdicionada(componente){
      this.fastIndicadorAdicionadaLoading = true
      this.fastIndicadorNovo.id_base_curricular_componente = componente.id_base_curricular_componente
      this.fastIndicadorEditar.id_base_curricular_componente = componente.id_base_curricular_componente
      this.showModal('modalBaseIndicadorAdicionada')
      this.promiseGetFastApi("api/projeto_base_curricular_componente_indicador/lista", "id_base_curricular_componente=" + componente.id_base_curricular_componente).then(obj => {
        if (obj.length) {
          this.fastIndicadorAdicionadaTotal = obj
          this.fastIndicadorAdicionadaFiltro = obj
        }
        else {
          this.fastIndicadorAdicionadaTotal = []
          this.fastIndicadorAdicionadaFiltro = []
        }            
        this.fastIndicadorAdicionadaLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar competências", "error");
        this.fastIndicadorAdicionadaLoading = false
    })
    },
    async criarIndicador() {
      if (!this.fastIndicadorNovo.nome_indicador) {
        this.exibeToasty("O nome é obrigatório", "error");
      } else {
        this.promisePostFastApi(this.fastIndicadorNovo, "api/projeto_base_curricular_componente_indicador/insere").then((res) => {            
            this.exibeToasty("Indicador adicionada com sucesso", "success");
            this.fastIndicadorAdicionadaTotal = res
            this.fastIndicadorAdicionadaFiltro = res
            this.showModal('modalBaseIndicadorAdicionada', 'modalBaseIndicadorNovo')

            this.fastComponentes.forEach(c => { if (c.id_base_curricular_componente == this.fastIndicadorNovo.id_base_curricular_componente) c.total_indicador++ })

            this.fastIndicadorNovo.nome_indicador = ""
        }).catch((e) => {
            this.exibeToasty("Erro ao inserir indicador", "error");
        });
      }
    },
    exibeModalBaseIndicadorEditar(indicador){
      this.fastIndicadorEditar = indicador
      this.showModal('modalBaseIndicadorEditar', 'modalBaseIndicadorAdicionada')
    },
    async editarIndicador(){
      if (!this.fastIndicadorEditar.nome_indicador) {
        this.exibeToasty("O nome é obrigatório", "error");
      } else {
      this.promisePostFastApi(this.fastIndicadorEditar, "api/projeto_base_curricular_componente_indicador/atualiza").then((res) => {   
            this.fastIndicadorAdicionadaTotal = res
            this.fastIndicadorAdicionadaFiltro = res         
            this.exibeToasty("Indicador atualizado com sucesso", "success");
            this.showModal('modalBaseIndicadorAdicionada', 'modalBaseIndicadorEditar')
        }).catch((e) => {
            this.exibeToasty("Erro ao atualizar indicador", "error");
        });
      }
    },
    async removerIndicador(indicador){
      this.fastIndicadorEditar.id_indicador = indicador.id_indicador
      this.promisePostFastApi(this.fastIndicadorEditar, "api/projeto_base_curricular_componente_indicador/exclui").then((res) => {            
          this.exibeToasty("Indicador excluída com sucesso", "success");
          this.fastIndicadorAdicionadaTotal = res
          this.fastIndicadorAdicionadaFiltro = res
          this.fastComponentes.forEach(c => { if (c.id_base_curricular_componente == this.fastIndicadorEditar.id_base_curricular_componente) c.total_indicador-- })
      }).catch((e) => {
          this.exibeToasty("Erro ao excluir indicador", "error");
      });
    },
    // PDF
    gerarPdfBaseCurricular(){
      console.log("this.fastBaseCurricular", this.fastBaseCurricular)
      console.log("this.fastComponentes", this.fastComponentes)

      this.$store.state.fastCarregando = true;

      let bibliografias = []
      let competencias = []
      let indicadores = []

      // Teste

      this.htmlBaseCurricular = `
      <div class="container">
        <div class="row">
          <div class="col-6">
            <img src="https://localhost:8080/img/logo_horizontal.21ef65ab.png" style="max-width: 200px">
          </div>
          <div class="col-6 text-right">
            <h5 class="text-info">Educação de Jovens e Adultos</h5>
            <h5 class="text-info">Modular</h5>
            <h5 class="text-info">Exames de Conclusão de Etapas - Provão</h5>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 text-center">
            <h5 class="text-info">Edital 001/2021</h5>
          </div>
          <div class="col-12 text-center mb-2">
            <p>REQUERIMENTO DE INSCRIÇÃO DOS EXAMES DE FINAIS DE ETAPAS - PROVÃO</p>
          </div>
          <div class="col-12">
            <label>Nome:</label> %nome_aluno
          </div>
          <div class="col-6">
            <label>Local de nascimento:</label> %local_nascimento
          </div>
          <div class="col-2">
            <label>UF:</label> %uf
          </div>
          <div class="col-4">
            <label>Data de nascimento:</label> %data_nascimento
          </div>
          <div class="col-5">
            <label>Nacionalidade:</label> ( ) Brasileira ( ) Estrangeira
          </div>
          <div class="col-3">
            <label>RG:</label> %rg
          </div>
          <div class="col-4">
            <label>CPF:</label> %cpf
          </div>
          <div class="col-6">
            <label>End:</label> %logradouro
          </div>
          <div class="col-2">
            <label>nº:</label> %numero
          </div>
          <div class="col-4">
            <label>CEP:</label> %cep
          </div>
          <div class="col-4">
            <label>Cidade:</label> %cidade
          </div>
          <div class="col-4">
            <label>Bairro:</label> %bairro
          </div>
          <div class="col-4">
            <label>Complemento:</label> %complemento
          </div>
          <div class="col-12">
            <label>Telefone:</label> %telefone
          </div>
          <div class="col-4">
            <label>Aluno EJA:</label> ( ) Sim ( ) Não
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 text-center mb-4">
            <h6>DISCIPLINAS DE INSCRIÇÃO</h6>
            <h6>(Escolher Ensino Fundamental e/ou Ensino Médio)</hh65>
          </div>
          <div class="col-6 table-responsive">
            <table class="table table-sm table-bordered">
              <tr class="text-center">
                <th colspan="2" class="p-0">ENSINO FUNDAMENTAL</th>
              </tr>
              <tr class="text-center">
                <th colspan="2" class="p-0">DISCIPLINA</th>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td><small>Língua Portuguesa e Redação</small></td>
              </tr>
              <tr>
                <td></td>
                <td><small>Língua Inglesa</small></td>
              </tr>
              <tr>
                <td></td>
                <td><small>História</small></td>
              </tr>
              <tr>
                <td></td>
                <td><small>Matemática</small></td>
              </tr>
              <tr>
                <td></td>
                <td><small>Educação física</small></td>
              </tr>
              <tr>
                <td></td>
                <td><small>Ciências</small></td>
              </tr>
              <tr>
                <td></td>
                <td><small>Arte</small></td>
              </tr>
              <tr>
                <td></td>
                <td><small>Geografia</small></td>
              </tr>
              <tr>
                <td></td>
                <td><small>Educação Religiosa</small></td>
              </tr>
            </table>
          </div>
          <div class="col-6 table-responsive">
            <table class="table table-sm table-bordered">
              <tr class="text-center">
                <th colspan="2" class="p-0">ENSINO MÉDIO</th>
              </tr>
              <tr class="text-center">
                <th colspan="2" class="p-0">DISCIPLINA</th>
              </tr>
              <tr>
                <td class="p-0">&nbsp;&nbsp;&nbsp;</td>
                <td class="p-0"><small>Língua Portuguesa/ Literatura</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Língua Inglesa</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>História</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Língua Espanhola</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Matemática</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Biologia</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Física</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Geografia</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Química</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>História do Estado de Rondônia</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Geografia do Estado de Rondônia</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Arte</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Educação Física</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Sociologia</small></td>
              </tr>
              <tr>
                <td class="p-0"></td>
                <td class="p-0"><small>Filosofia</small></td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <p>Venho requerer minha inscrição nos EJA Modular - EJA/2021 no ensino e disciplinas assinaladas, dentro dos critérios estabelecidos e atesto serem verídicas as informações aqui prestadas.</p>
          </div>
          <div class="col-12 text-right mt-2">
            <p>Buritis, ___________ de _________________________ de 2021</p>
          </div>
          <div class="col-12 text-center mt-2">
            ______________________________________________________________________________
            <p class="m-0">Assinatura do (a) candidato (a)</p>
            <small>(Conforme identificação)</small>
          </div>
          <div class="col-12 text-center mt-2">
            ______________________________________________________________________________
            <p>Assinatura do responsável pela inscrição do condidato</p>
          </div>
          <div class="col-12 text-center mt-2">
            <h6>(ANEXAR AO REQUERIMENTO CÓPIA DO DOCUMENTO DE IDENTIFICAÇÃO)</h6>
            <p>Mantenedora/Diretora</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 text-center">
            <p>Rua Nova União, n. 2024 - Setor 02 Buritis-RO - CEP 76880-000</p>
            <p>sestedcursos@gmail.com - Tel. (69) 99694538</p>
          </div>
        </div>
      </div>`
      
      this.$refs.html2Pdf.generatePdf();
      this.$store.state.fastCarregando = false;

/*
      // Criar método na api para retornar todas as bibliografias, competências e indicadores da base curricular
      this.promiseGetFastApi("api/projeto_base_curricular_bibliografia/lista_todas", "id_base_curricular=" + this.fastBaseCurricular.id_base_curricular)
      .then(obj => bibliografias = obj )
      .then(() => {
        return this.promiseGetFastApi("api/projeto_base_curricular_componente_competencia/lista_todas", "id_base_curricular=" + this.fastBaseCurricular.id_base_curricular)       
      })
      .then(obj => competencias = obj) 
      .then(() => {
        return this.promiseGetFastApi("api/projeto_base_curricular_componente_indicador/lista_todos", "id_base_curricular=" + this.fastBaseCurricular.id_base_curricular)
      })
      .then(obj => indicadores = obj)
      .then(() => {
        let html = `
        <h3 class="mb-4">${this.fastBaseCurricular.nome_base_curricular}</h3>
        <p class="mb-4"><b>Lista de componentes:</b></p>
        `
        this.fastComponentes.forEach((c, index) => {
          let count = index + 1
          html += `
            <p class="mt-2"><b>${count} - ${c.unidade_curricular_tematica}</b></p>
          `
          html += `<p><b>${count}.1 - Bibliografias:</b></p>`
          if (bibliografias.length) {  
            let bibliografiasQtd = 0          
            bibliografias.forEach(b => {
              if (b.id_base_curricular_componente == c.id_base_curricular_componente) {
                bibliografiasQtd++
                html += `<span>${b.autora}. ${b.nome_livro}. ${b.ano}</span><br/>`
              }
            })
            if (!bibliografiasQtd) html += `<span>Nenhuma</span><br/>`
            html += `<br>`
          } 

          html += `<p><b>${count}.2 - Competências:</b></p>`
          if (competencias.length) {            
            let competenciasQtd = 0
            competencias.forEach(b => {
              if (b.id_base_curricular_componente == c.id_base_curricular_componente) {
                competenciasQtd++
                html += `<span>${b.nome_competencia}</span><br/>`
              }
            })            
            if (!competenciasQtd) html += `<span>Nenhuma</span><br/>`
            html += `<br>`
          } 

          html += `<p><b>${count}.3 - Indicadores:</b></p>`
          if (indicadores.length) {   
            let indicadoresQtd = 0             
            indicadores.forEach(b => {
              if (b.id_base_curricular_componente == c.id_base_curricular_componente) {
                indicadoresQtd++
                html += `<span>${b.nome_indicador}</span><br/>`
              }
            })
            if (!indicadoresQtd) html += `<span>Nenhum</span><br/>`
            html += `<br>`
          } 
        })

        this.htmlBaseCurricular = html
        this.$refs.html2Pdf.generatePdf();
        this.$store.state.fastCarregando = false;
      })
      .catch(e => { 
        this.$store.state.fastCarregando = false;
        this.exibeToasty("Erro ao buscar dados", "error");
      })
      */
      
    },
    // Disciplinas
    async getFastDisciplinas() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_disciplinas/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {  
          this.fastDisciplinas = obj;
          console.log(this.fastDisciplinas)
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

/* Ajuste para gerador de pdf */
.vue-html2pdf p {
  margin: 0 !important;
  padding: 0 !important;  
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
</style>
